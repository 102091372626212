import p5 from 'p5'

export const logo = (p: p5, rms: number) => {
  // const scale = 7
  const unitSize = p.width / 7
  const unitMargin = unitSize / 10
  const logoWidth = unitSize * 4.5 + unitMargin * 5
  const startX = (p.width - logoWidth) / 2

  const logoDo = (x: number, size: number, margin: number) => {
    const soundSize = size + rms * 200
    p.noStroke()
    p.fill(0)
    p.arc(x, 0, size, size, p.PI / 2 - p.PI, p.PI / 2)
    p.ellipse(x + size + margin, 0, soundSize)
  }

  p.translate(startX, p.height / 2)

  logoDo(0, unitSize, unitMargin)
  logoDo(unitSize * 1.5 + unitMargin * 2, unitSize, unitMargin)
  logoDo(unitSize * 3 + unitMargin * 4, unitSize, unitMargin)
}
