import React from 'react'
import Img from 'gatsby-image'

import styles from './portfolioList.module.css'

import { IPortfolio } from '../../../graphql/IPortfolio'
import { fileExtension } from '../../../helpers/extractFileExtension'

interface Props {
  project: IPortfolio
}

export const Thumbnail: React.FunctionComponent<Props> = ({ project }) => {
  const item = project.node
  const { title, thumbnail } = item.frontmatter

  const extension = fileExtension(thumbnail.publicURL)

  const renderImage = (extension: string) => {
    switch (extension) {
      case 'gif':
        return <img className={styles.gif} src={thumbnail.publicURL} alt={title} />
      case 'jpg':
      case 'png':
        return <Img fluid={thumbnail.childImageSharp.fluid} alt={title} />
      case 'mp4':
        return <source src={thumbnail.publicURL} type="video/mp4" />
    }
  }

  return <>{renderImage(extension)}</>
}
