import React, { useMemo, useContext, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
// import { usePageVisibility } from 'react-page-visibility'
import P5Wrapper from 'react-p5-wrapper'
import 'p5/lib/addons/p5.sound'

import styles from './canvas.module.css'

import { sketch } from './sketches/song/sketch'
import { myContext } from '../../../provider'

export const Canvas = () => {
  // const isWindowActive = usePageVisibility()
  const memoizedSketch = useMemo(() => sketch, [])
  const { userHasInteracted, updateUserHasInteracted } = useContext(myContext)
  const [ref, inView] = useInView()
  // const isCanvasActive = isWindowActive && inView
  const isCanvasActive = inView

  // USER HAS INTERACTED
  useEffect(() => {
    if (!userHasInteracted) {
      const handleUserHasInteracted = () => {
        updateUserHasInteracted(true)
      }
      window.addEventListener('click', handleUserHasInteracted, { once: true })
      return () => {
        window.removeEventListener('click', handleUserHasInteracted)
      }
    }
  })

  return (
    <>
      {typeof window !== `undefined` ? ( // Avoid Gatsby errors on Build
        <div ref={ref}>
          <P5Wrapper
            sketch={memoizedSketch}
            userHasInteracted={userHasInteracted}
            isCanvasActive={isCanvasActive}
          />
          <div id="p5_loading" className={styles.loading}></div>
        </div>
      ) : null}
    </>
  )
}
