import React from 'react'

import styles from './video.module.css'

import { SelfHostedVideo } from './SelfHostedVideo'
import { EmbedVideo } from './EmbedVideo'

interface Props {
  title: string
  isSelfHostedVideo: boolean
  selfHostedVideo?: {
    publicURL: string
  }
  embedVideo?: string
}

export const Video: React.FC<Props> = props => {
  const { title, isSelfHostedVideo, selfHostedVideo, embedVideo } = props

  return (
    <>
      {isSelfHostedVideo && selfHostedVideo ? (
        <SelfHostedVideo video={selfHostedVideo?.publicURL} />
      ) : embedVideo ? (
        <div className={styles.embedWrapper}>
          <EmbedVideo videoUrl={embedVideo} title={title} />
        </div>
      ) : null}
    </>
  )
}
