import React, { useEffect, useContext } from 'react'

import { PortfolioList } from '../components/portfolio/list/PortfolioList'
import Layout from '../components/layout/Layout'
import { SEO } from '../components/SEO'

import { myContext } from '../provider'
import { Reel } from '../components/reel/Reel'
import { Hero } from '../components/hero/Hero'

const IndexPage: React.FunctionComponent = () => {
  const context = useContext(myContext)
  useEffect(() => {
    context.updateActivePage('work')
  }, [context])

  return (
    <>
      <Layout>
        <SEO title={'Work'} />
        <Hero />
        <Reel />
        <PortfolioList />
      </Layout>
    </>
  )
}

export default IndexPage
