import React from 'react'

import { usePortfolioData } from '../../../graphql/usePortfolioData'

import styles from './portfolioList.module.css'
import { Item } from './Item'
import { createPortfolioMatrix } from './createPortfolioMatrix'

export const PortfolioList: React.FunctionComponent = () => {
  const portfolioItems = usePortfolioData()

  // Filter out projects if they don't have thumbnail.
  // Otherwise the portfolioMatrix will complain
  const portfolioItemsWithThumbnails = portfolioItems.filter(
    item => item.node.frontmatter.thumbnail !== null
  )

  const portfolioMatrix = createPortfolioMatrix(portfolioItemsWithThumbnails, 2)

  return (
    <section>
      <div className={styles.container}>
        {portfolioMatrix.map((col, index) => (
          <ul key={index} className={styles.col}>
            {col.map(item => {
              return (
                <li key={item.node.fields.slug}>
                  <Item project={item}></Item>
                </li>
              )
            })}
          </ul>
        ))}
      </div>
    </section>
  )
}
