import React from 'react'

import styles from './reel.module.css'

import {
  is_self_hosted_video,
  self_hosted_video,
  embed_video
} from '../../../content/data/reel.json'
import { useMedia } from '../../graphql/useMedia'
import { Video } from '../video/Video'

export const Reel: React.FunctionComponent = () => {
  const selfHostedVideo = useMedia(self_hosted_video)

  return (
    <div className={styles.reel}>
      {self_hosted_video || embed_video ? (
        <Video
          title={'DODODO Reel'}
          embedVideo={embed_video}
          isSelfHostedVideo={is_self_hosted_video}
          selfHostedVideo={selfHostedVideo?.node}
        />
      ) : null}
    </div>
  )
}
