import p5, { Amplitude, SoundFile } from 'p5'
import { P5WithLoadSound } from '../../p5WithLoadSound'
import { logo } from './logo'
const songFile = require('./song.mp3')

interface P5 extends P5WithLoadSound {
  myCustomRedrawAccordingToNewPropsHandler: (props: P5) => void
  getAudioContext: () => AudioContext
  isCanvasActive: boolean
  userHasInteracted: boolean
}

export const sketch = (p: P5) => {
  let w = document.body.clientWidth
  let h = document.body.clientWidth / 5

  let userHasInteracted: boolean
  let isCanvasActive: boolean

  let song: SoundFile
  let analyzer: Amplitude

  p.myCustomRedrawAccordingToNewPropsHandler = (props: P5) => {
    if (props.isCanvasActive !== null) {
      isCanvasActive = props.isCanvasActive
    }
    if (props.userHasInteracted) {
      userHasInteracted = true
    }
  }

  p.preload = () => {
    song = p.loadSound(songFile)
  }

  p.setup = () => {
    // // mimics the autoplay policy
    // p.getAudioContext().suspend()

    p.createCanvas(w, h)

    // create a new Amplitude analyzer
    analyzer = new p5.Amplitude()

    // Patch the input to an volume analyzer
    analyzer.setInput(song)
  }

  p.mouseMoved = () => {
    const mouseIsOver = p.mouseX > 0 && p.mouseX < p.width && p.mouseY > 0 && p.mouseY < p.height
    if (mouseIsOver) {
      if (userHasInteracted && song.isLoaded() && !song.isPlaying()) {
        song.loop()
      }
    } else {
      song.pause()
    }
  }

  p.mouseClicked = () => {
    const mouseIsOver = p.mouseX > 0 && p.mouseX < p.width && p.mouseY > 0 && p.mouseY < p.height
    if (mouseIsOver) {
      if (song.isLoaded() && !song.isPlaying()) {
        userHasInteracted = true
        song.loop()
      } else if (song.isPlaying()) {
        song.pause()
      }
    }
  }

  p.draw = () => {
    if (isCanvasActive === false && song.isPlaying()) {
      song.pause()
    }

    p.background(255)

    // Get the average (root mean square) amplitude
    // We'll pass it to the logo
    let rms = analyzer.getLevel()

    logo(p, rms)
  }

  p.windowResized = () => {
    let w = document.body.clientWidth
    let h = document.body.clientWidth / 5
    p.resizeCanvas(w, h)
  }
}
