import React from 'react'
import { Link } from 'gatsby'

import styles from './portfolioList.module.css'

import { IPortfolio } from '../../../graphql/IPortfolio'
import { Thumbnail } from './Thumbnail'

interface Props {
  project: IPortfolio
}

export const Item: React.FunctionComponent<Props> = ({ project }) => {
  const item = project.node
  const { title, thumbnail } = item.frontmatter
  const { slug } = item.fields

  return (
    <>
      <Link to={`/${slug}`}>
        {thumbnail ? <Thumbnail project={project}></Thumbnail> : null}
        <h2 className={styles.title}>{title}</h2>
      </Link>
    </>
  )
}
