import React from 'react'

import styles from '../portfolio/project/project.module.css'

interface Props {
  video: string
}

export const SelfHostedVideo: React.FC<Props> = ({ video }) => {
  return (
    <>
      {video ? (
        <div className={styles.video}>
          <video controls>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      ) : null}
    </>
  )
}
