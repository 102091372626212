import React from 'react'

import styles from './hero.module.css'

// import { Logo } from '../logo/Logo'
// import { Canvas } from './Canvas'
import { Canvas } from './canvas/Canvas'

export const Hero: React.FunctionComponent = () => {
  return (
    <div className={styles.hero}>
      {/* <div className={styles.logo}>
        <Logo />
      </div> */}
      <Canvas />
      <h2 className={styles.text}>Playful Music & Sound Design</h2>
    </div>
  )
}
