import React from 'react'
import { youtubeVideoId } from '../../helpers/extractYoutubeVideoId'
import { vimeoVideoId } from '../../helpers/extractVimeoVideoId'

interface Props {
  videoUrl: string
  title: string
}

export const EmbedVideo: React.FC<Props> = ({ videoUrl, title }) => {
  const embedUrl = (videoUrl: string) => {
    const isYoutube = videoUrl.includes('youtu')
    const isVimeo = videoUrl.includes('vimeo')

    if (isYoutube) {
      const id = youtubeVideoId(videoUrl)
      return 'https://www.youtube.com/embed/' + id
    } else if (isVimeo) {
      const id = vimeoVideoId(videoUrl)
      return 'https://player.vimeo.com/video/' + id
    }

    return undefined
  }

  return (
    <iframe
      // width="560"
      // height="315"
      title={title}
      src={embedUrl(videoUrl)}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen={true}
    ></iframe>
  )
}
