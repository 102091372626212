import { IPortfolio } from '../../../graphql/IPortfolio'

export const createPortfolioMatrix = (
  items: IPortfolio[],
  numOfColumns: number
): IPortfolio[][] => {
  const colHeights = Array(numOfColumns).fill(0)
  const matrix = Array(numOfColumns).fill([])

  const buildMatrix = () => {
    items.reduce((_acc, item) => {
      // Get shorter Column Index
      const colIndex = colHeights.indexOf(Math.min(...colHeights))

      // We have to calculate the aspect ratio because fluid.aspectRatio is the wrong ratio
      const {
        presentationWidth,
        presentationHeight
      } = item.node.frontmatter.thumbnail.childImageSharp.fluid
      const aspectRatio = presentationHeight / presentationWidth

      // Add item to Matrix
      matrix[colIndex] = [...matrix[colIndex], item]

      // Increase col height
      return (colHeights[colIndex] = parseFloat((colHeights[colIndex] + aspectRatio).toFixed(4)))
    }, 0)
  }
  buildMatrix()
  return matrix
}
