import { graphql, useStaticQuery } from 'gatsby'
import { IPortfolio } from './IPortfolio'

export const usePortfolioData = (): IPortfolio[] => {
  const data = useStaticQuery(graphql`
    query getBlogData {
      allMarkdownRemark(sort: { order: ASC, fields: frontmatter___order }) {
        edges {
          node {
            id
            frontmatter {
              order
              title
              description
              credits
              year
              embed_video
              is_self_hosted_video
              # self_hosted_video {
              #   publicURL
              # }
              thumbnail {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  return data.allMarkdownRemark.edges
}
